import React, { useState, useEffect } from "react";
import { Container, Button, Form } from "react-bootstrap";
import { Col, Row } from "antd";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import axios from "axios";
import * as Icon from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Spinner } from "react-bootstrap";
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';

function WithdrawAmount() {
  const [withdrawAmount, setWithdrawAmount] = useState("");
  const [paymentOption, setPaymentOption] = useState("");
  const navigate = useNavigate();
  const [loadingbutton, setLoadingbutton] = useState(false);
  const handleWithdraw = async () => {
    const user_id = localStorage.getItem("userid");
    const dev_id = localStorage.getItem("dev_id");

    // Define a map for payment option types
    const typeMap = {
      1: "phone_pay",
      2: "paytm",
      3: "google_pay",
      4: "Bank",
    };

    try {
      if (!withdrawAmount) {

        toast.error('Please Enter a Valid Digit Number.');
        return;
      }
      if (!paymentOption) {

        toast.error('Please Enter a Valid Payment Option.');
        return;
      }
      setLoadingbutton(true);
      const formData = new FormData();
      formData.append("app_id", process.env.REACT_APP_API_ID);
      formData.append("user_id", user_id);
      formData.append("amount", withdrawAmount);
      formData.append("device_id", dev_id);

      // Include additional parameters based on the payment option
      if (paymentOption === "1") {
        formData.append("type", "phonepay");
        // Add phone_pay specific parameters
      } else if (paymentOption === "2") {
        formData.append("type", "paytm");
        // Add paytm specific parameters
      } else if (paymentOption === "3") {
        formData.append("type", "googlepay");
        // Add google_pay specific parameters
      } else if (paymentOption === "4") {
        formData.append("type", "bank");
        formData.append("account_number", "account_number");
        formData.append("bank_name", "bank_name");
        formData.append("ifsc_code", "ifsc_code");
        formData.append("account_holder_name", "account_holder_name");
        // Add Bank specific parameters
      }

      const response = await fetch(`${process.env.REACT_APP_API_URL}/Withdraw.php`, {
        method: "POST",
        body: formData,
      });
      const MySwal = withReactContent(Swal);
      if (response.ok) {
        try {
          const responseData = await response.json(); // Parse the response JSON
          console.warn(responseData)

          if (responseData.success === "3") {
            MySwal.fire({
              title: responseData.message,
              icon: 'success',
              timer: 2500
            }).then((result) => {
              navigate('/');
            });
          } else {
            MySwal.fire({
              title: responseData.message,
              timer: 2000,
              icon: 'error',
            });
          }
        } catch (error) {
          console.error("Error parsing response:", error);
          MySwal.fire({
            title: "Error",
            text: "Something went wrong. Please try again.",
            icon: 'error',
          });
        }
      } else {
        console.error("Server responded with an error:", response.statusText);
        MySwal.fire({
          title: "Error",
          text: "Something went wrong. Please try again.",
          icon: 'error',
        });
      }
    } catch (error) {
      console.error("Error submitting payment details:", error);
      // MySwal.fire({
      //   title: "Error",
      //   text: "Something went wrong. Please try again.",
      //   icon: 'error',
      // });
    }
    finally {
      setLoadingbutton(false);
    }
  };

  













  return (
    <div className="Add_bank-detail WithdrawAmt">
      <Container className="mt-5">
        <div className="info_box">
          <p>Withdraw available from 7:00 AM to 10:00 pm</p>
        </div>

        <div className="inputbox mt-1">
          <input
            type="number"
            className="form-control"
            placeholder="Withdraw"
            value={withdrawAmount}
            onChange={(e) => setWithdrawAmount(e.target.value)}
          />
        </div>

        <div className="inputbox">
          <Form.Select
            aria-label="Default select example"
            value={paymentOption}
            onChange={(e) => setPaymentOption(e.target.value)}
          >
            <option>Select Payment Option</option>
            <option value="1">PhonePe</option>
            <option value="2">Paytm</option>
            <option value="3">GPay</option>
            <option value="4">Bank</option>
          </Form.Select>
        </div>

        <div className="inputbox mt-5">
          <Button type="submit" disdisabled={loadingbutton} className="w-100 Submit_btn" onClick={handleWithdraw}>
            Withdraw
            {loadingbutton && <Spinner animation="border" />}
          </Button>
        </div>

        {/* <Row justify={"space-between"} className="linkBox">
          <Col xs={24}>
            <h5 className="mt-2">Withdraw Method</h5>
          </Col>
          <Link to="/Withdrawhistory">
            <div className="">
              <p>Withdraw History</p>
            </div>
          </Link>

          <Link to="/Add-Bank-Detais">
            <div className="">
              <p>Add Bank Detail</p>
            </div>
          </Link>
        </Row> */}
      </Container>
      <ToastContainer />
    </div>
  );
}

export default WithdrawAmount;
