import React, { useState, useEffect } from "react";
import { Row, Col, Container } from "react-bootstrap";
// import { Link } from "react-router-dom";
import { Link, useLocation } from 'react-router-dom';

import single from "../../assets/img/single.png";
import jodi from "../../assets/img/jodi.png";
import card from "../../assets/img/card.png";
import card3 from "../../assets/img/card3.png";
import double from "../../assets/img/double.png";
import full_card from "../../assets/img/full_card.png";
import singlepanna from "../../assets/img/singlepanna.jpg";
import axios from "axios";
import { FaArrowLeft } from "react-icons/fa";
import * as Icon from 'react-bootstrap-icons';
import { useNavigate } from 'react-router-dom';


const Playgamedelhi = () => {
  const market_name = localStorage.getItem("market_name");
  const [profiles, setProfiles] = useState([]);
  const navigate = useNavigate();

  // const isPlayPage = location.pathname === '/Disawar-Market';
  const location = useLocation();

  const [users, setUsers] = useState("");
  const url = new URL(window.location.href);
  const gameid = url.searchParams.get('id');
  const url1 = new URL(window.location.href);
  const name = url1.searchParams.get('name');;
  //   alert(gameid)
  console.warn(gameid)
  useEffect(() => {
    loaduser();
  }, []);

  const loaduser = async () => {
    const user_id = localStorage.getItem("userid");
    const dev_id = localStorage.getItem("dev_id");

    let url = `${process.env.REACT_APP_API_URL}/get_user_profile.php`;
    const formData = new FormData();
    formData.append("app_id", process.env.REACT_APP_API_ID);
    formData.append("user_id", user_id);
    formData.append("dev_id", dev_id);

    var config = {
      method: "POST",
      url: url,
      data: formData, // Use 'data' instead of 'body'
    };

    axios(config)
      .then(function (response) {
        console.log("API Response:", response);
        const res = response.data;
        if (res !== undefined) {
          // alert(res);
          setProfiles(res);
          console.warn(res);
        }
      })
      .catch(function (error) {
        console.error("Error fetching profile data: ", error);
      });
  };
  const handelclick = () => {
    // alert('hhh')
    navigate(-1);
  }
  return (
    <>

      <div className="header-top">
        <Container>
          <Row className="d-flex align-items-center justify-content-between">
            <Col xs={2} md={2} s={2}>
              <Link onClick={handelclick} class="arrowlink">
                <FaArrowLeft />
              </Link>
            </Col>
            <Col xs={8} md={8} s={8}>
              <div className="menuicon d-flex justify-content-center">
                <div className="d-flex">
                  <div class="headericonarrow">
                  </div>
                  {market_name}
                </div>

              </div>
            </Col>

            <Col xs={2} md={2} s={2}>
              <div className="d-flex justify-content-end align-items-center">
                <div className="d-flex align-items-center wallet">
                  <Icon.Wallet /> :
                  {profiles.credit}
                </div>

              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <section id="pana" className="margin-bottom-88 mt-5">
        <Container>
          <span className="ml-10px mt-10" style={{ background: "red" }}> </span>

          {/* <Row className="all_pana_box" style={{marginTop:'200px', marginLeft:'30px'}}>
        
        <Col xs={6}>
            <Link className="pana_box" to="/Play-Jodi">
            <div className="panna_img">
                <img src={card} alt="pana" className="img-fluid"/>
                </div>
                <span>Jodi</span>
            </Link>
        </Col>
        <Col xs={6}>
            <Link className="pana_box" to="/Play-Harruf">
            <div className="panna_img">
                <img src={full_card} alt="pana" className="img-fluid"/>
                </div>
                <span>Harrf</span>
            </Link>
        </Col>
    </Row> */}
          <Row className="all_pana_box">
            {/* <Col xs={6} >
              <Link className="pana_box" to="/Harruf">
                <div className="panna_img">
                  <img src={single} alt="pana" className="img-fluid" />
                </div>
                <span>Huruf Andar bahar</span>
              </Link>
            </Col> */}
            {/* <Col xs={6}>
              <Link className="pana_box" to="/Jodi">
                <div className="panna_img">
                  <img src={jodi} alt="pana" className="img-fluid" />
                </div>
                <span>Play Jodi</span>
              </Link>
            </Col> */}
            <Col xs={6} className="" style={{marginLeft:"105px"}}>
            <Link className="pana_box" to="/Harruf">
                <div className="panna_img">
                <img src={single} alt="pana" className="img-fluid"/>
                </div>
                <span>Huruf Andar bahar</span>
            </Link>
        </Col>
        <Col xs={6}>
            <Link className="pana_box" to="/Jodi">
            <div className="panna_img">
                <img src={jodi} alt="pana" className="img-fluid"/>
                </div>
                <span>Play Jodi</span>
            </Link>
        </Col>
            <Col xs={6} className="">
            <Link className="pana_box" to="/Number-Number">
            <div className="panna_img">
                <img src={singlepanna} alt="pana" className="img-fluid"/>
                </div>
                <span>Number To Number</span>
            </Link>
        </Col>
        <Col xs={6}>
            <Link className="pana_box" to="/Play-Rashi-Jodi">
            <div className="panna_img">
                <img src={double} alt="pana" className="img-fluid"/>
                </div>
                <span>Play Rashi Jodi</span>
            </Link>
        </Col>
        <Col xs={6}>
            <Link className="pana_box" to="/Crossing">
            <div className="panna_img">
                <img src={card3} alt="pana" className="img-fluid"/>
                </div>
                <span>Play Crossing</span>
            </Link>
        </Col>
        <Col xs={6}>
            <Link className="pana_box" to="/Play-Jantri">
            <div className="panna_img">
                <img src={card} alt="pana" className="img-fluid"/>
                </div>
                <span>Play jantri</span>
            </Link>
        </Col>
        <Col xs={6}>
            <Link className="pana_box" to="/Haruf-Base-Jodi">
            <div className="panna_img">
                <img src={full_card} alt="pana" className="img-fluid"/>
                </div>
                <span>Haruf Based Jodi</span>
            </Link>
        </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Playgamedelhi;
