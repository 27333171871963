import React, { useState, useEffect } from 'react';
import rup from "../../assets/img/rup.jpg";
import { Container } from 'react-bootstrap';
import axios from "axios";
import * as Icon from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';


function Marcetret() {

  const [users, setUserss] = useState("")
  const [user, setUsers] = useState("")
  console.warn(users)
  const [selectedMarket, setSelectedMarket] = useState('1');

  const handleMarketChange = (event) => {
    setSelectedMarket(event.target.value);
  };

  useEffect(() => {
    kalyanGameRate();
    mainGameRate();
  }, [])
  const kalyanGameRate = async () => {
    const user_id = localStorage.getItem('userid');
    const dev_id = localStorage.getItem('dev_id');
    let url = `${process.env.REACT_APP_API_URL}/kalyanGameRate.php`;
    const formData = new FormData();
    formData.append('app_id', process.env.REACT_APP_API_ID);
    formData.append('user_id', user_id);
    formData.append('dev_id', dev_id);
    var config = {
      method: 'POST',
      url: url,
      body: formData,
    };
    axios.post(url, formData, config).then(function (response) {
      const res = JSON.stringify(response.data.data);
      const objectRes = JSON.parse(res);
      setUserss(objectRes[0]);
    });
  };
  const mainGameRate = async () => {
    const user_id = localStorage.getItem('userid');
    const dev_id = localStorage.getItem('dev_id');
    let url = `${process.env.REACT_APP_API_URL}/mainGameRate.php`;
    const formData = new FormData();
    formData.append('app_id', process.env.REACT_APP_API_ID);
    formData.append('user_id', user_id);
    formData.append('dev_id', dev_id);
    var config = {
      method: 'POST',
      url: url,
      body: formData,
    };
    axios.post(url, formData, config).then(function (response) {
      const res = JSON.stringify(response.data.data);
      const objectRes = JSON.parse(res);
      setUsers(objectRes[0]);
    });
  };

  return (
    <div className='market_ret'>
      <Container fluid>
        <Form.Select aria-label="Default select example " className='form_dropdown_select form-select' onChange={handleMarketChange} value={selectedMarket}>
          <option value="1">Delhi Market</option>
          <option value="2">Kalyan Market</option>
        </Form.Select>
        {selectedMarket === '1' && (
          <>
            <div className="box_marketrate">
              <div className="contentrate">
                <h3>Jodi</h3>
              </div>
              <div className="d-flex justify-content-around align-items-center details_rate">
                <div className='text-center text-dark'>
                  <h3>Bid Amount <span className='color_orange'><i className="bi bi-currency-rupee"></i>{user.jodi_digits_min_bid
                  }</span></h3>
                </div>
                <div className='text-center text-dark'>
                  <h3>Win Amount <span className='color_green'><i className="bi bi-currency-rupee"></i>{user.jodi_digits_max_bid}</span></h3>

                </div>

              </div>
            </div>
            <div className="box_marketrate">
              <div className="contentrate">
                <h3>Andar</h3>
              </div>
              <div className="d-flex justify-content-around align-items-center details_rate">
                <div className='text-center text-dark'>
                  <h3>Bid Amount <span className='color_orange'><i className="bi bi-currency-rupee"></i>{user.andar_min_bid}</span></h3>
                </div>
                <div className='text-center text-dark'>
                  <h3>Win Amount <span className='color_green'><i className="bi bi-currency-rupee"></i> {user.andar_max_bid}</span></h3>

                </div>

              </div>
            </div>
            <div className="box_marketrate">
              <div className="contentrate">
                <h3>Bahar</h3>
              </div>
              <div className="d-flex justify-content-around align-items-center details_rate">
                <div className='text-center text-dark'>
                  <h3>Bid Amount <span className='color_orange'><i className="bi bi-currency-rupee"></i>
                    {user.bahar_min_bid}</span></h3>
                </div>
                <div className='text-center text-dark'>
                  <h3>Win Amount <span className='color_green'><i className="bi bi-currency-rupee"></i>{user.bahar_max_bid}</span></h3>

                </div>

              </div>
            </div>
            {/* <div className="box_marketrate">
          <div className="contentrate">
              <h3>Double Pana</h3>
          </div>
          <div className="d-flex justify-content-around align-items-center details_rate">
              <div className='text-center text-dark'>
                 <h3>Bid Amount <span className='color_orange'><i className="bi bi-currency-rupee"></i>
                 {user.double_pana_value1}</span></h3>
              </div>
              <div className='text-center text-dark'>
                 <h3>Win Amount <span className='color_green'><i className="bi bi-currency-rupee"></i>{user.double_pana_value2}</span></h3>
                 
              </div>
           
          </div>
      </div>
      <div className="box_marketrate">
          <div className="contentrate">
              <h3>Triple Pana</h3>
          </div>
          <div className="d-flex justify-content-around align-items-center details_rate">
              <div className='text-center text-dark'>
                 <h3>Bid Amount <span className='color_orange'><i className="bi bi-currency-rupee"></i>
                 {user.triple_pana_value1}</span></h3>
              </div>
              <div className='text-center text-dark'>
                 <h3>Win Amount <span className='color_green'><i className="bi bi-currency-rupee"></i>{user.triple_pana_value2}</span></h3>
                 
              </div>
           
          </div>
      </div>
      <div className="box_marketrate">
          <div className="contentrate">
              <h3>Half Sangam</h3>
          </div>
          <div className="d-flex justify-content-around align-items-center details_rate">
              <div className='text-center text-dark'>
                 <h3>Bid Amount <span className='color_orange'><i className="bi bi-currency-rupee"></i>
                 {user.Half_Sangam_Value1}</span></h3>
              </div>
              <div className='text-center text-dark'>
                 <h3>Win Amount <span className='color_green'><i className="bi bi-currency-rupee"></i>{user.Half_Sangam_Value2}</span></h3>
                 
              </div>
           
          </div>
      </div>
      <div className="box_marketrate">
          <div className="contentrate">
              <h3>Full Sangam</h3>
          </div>
          <div className="d-flex justify-content-around align-items-center details_rate">
              <div className='text-center text-dark'>
                 <h3>Bid Amount <span className='color_orange'><i className="bi bi-currency-rupee"></i>
                 {user.full_Sangam_Value1}</span></h3>
              </div>
              <div className='text-center text-dark'>
                 <h3>Win Amount <span className='color_green'><i className="bi bi-currency-rupee"></i>{user.full_Sangam_Value2}</span></h3>
                 
              </div>
           
          </div>
      </div> */}
          </>
        )}
        {selectedMarket === '2' && (
          <>
            <div className="box_marketrate">
              <div className="contentrate">
                <h3>Single Digit</h3>
              </div>
              <div className="d-flex justify-content-around align-items-center details_rate">
                <div className='text-center text-dark'>
                  <h3>Bid Amount <span className='color_orange'><i className="bi bi-currency-rupee"></i>{users.single_digit_value1}</span></h3>
                </div>
                <div className='text-center text-dark'>
                  <h3>Win Amount <span className='color_green'><i className="bi bi-currency-rupee"></i>{users.single_digit_value2}</span></h3>

                </div>

              </div>
            </div>
            <div className="box_marketrate">
              <div className="contentrate">
                <h3>Jodi Digit</h3>
              </div>
              <div className="d-flex justify-content-around align-items-center details_rate">
                <div className='text-center text-dark'>
                  <h3>Bid Amount <span className='color_orange'><i className="bi bi-currency-rupee"></i>{users.jodi_digit_value1}</span></h3>
                </div>
                <div className='text-center text-dark'>
                  <h3>Win Amount <span className='color_green'><i className="bi bi-currency-rupee"></i> {users.jodi_digit_value2}</span></h3>

                </div>

              </div>
            </div>
            <div className="box_marketrate">
              <div className="contentrate">
                <h3>Single Pana</h3>
              </div>
              <div className="d-flex justify-content-around align-items-center details_rate">
                <div className='text-center text-dark'>
                  <h3>Bid Amount <span className='color_orange'><i className="bi bi-currency-rupee"></i>
                    {users.single_pana_value1}</span></h3>
                </div>
                <div className='text-center text-dark'>
                  <h3>Win Amount <span className='color_green'><i className="bi bi-currency-rupee"></i>{users.single_pana_value2}</span></h3>

                </div>

              </div>
            </div>
            <div className="box_marketrate">
              <div className="contentrate">
                <h3>Double Pana</h3>
              </div>
              <div className="d-flex justify-content-around align-items-center details_rate">
                <div className='text-center text-dark'>
                  <h3>Bid Amount <span className='color_orange'><i className="bi bi-currency-rupee"></i>
                    {users.double_pana_value1}</span></h3>
                </div>
                <div className='text-center text-dark'>
                  <h3>Win Amount <span className='color_green'><i className="bi bi-currency-rupee"></i>{users.double_pana_value2}</span></h3>

                </div>

              </div>
            </div>
            <div className="box_marketrate">
              <div className="contentrate">
                <h3>Triple Pana</h3>
              </div>
              <div className="d-flex justify-content-around align-items-center details_rate">
                <div className='text-center text-dark'>
                  <h3>Bid Amount <span className='color_orange'><i className="bi bi-currency-rupee"></i>
                    {users.triple_pana_value1}</span></h3>
                </div>
                <div className='text-center text-dark'>
                  <h3>Win Amount <span className='color_green'><i className="bi bi-currency-rupee"></i>{users.triple_pana_value2}</span></h3>

                </div>

              </div>
            </div>
            <div className="box_marketrate">
              <div className="contentrate">
                <h3>Half Sangam</h3>
              </div>
              <div className="d-flex justify-content-around align-items-center details_rate">
                <div className='text-center text-dark'>
                  <h3>Bid Amount <span className='color_orange'><i className="bi bi-currency-rupee"></i>
                    {users.Half_Sangam_Value1}</span></h3>
                </div>
                <div className='text-center text-dark'>
                  <h3>Win Amount <span className='color_green'><i className="bi bi-currency-rupee"></i>{users.Half_Sangam_Value2}</span></h3>

                </div>

              </div>
            </div>
            <div className="box_marketrate">
              <div className="contentrate">
                <h3>Full Sangam</h3>
              </div>
              <div className="d-flex justify-content-around align-items-center details_rate">
                <div className='text-center text-dark'>
                  <h3>Bid Amount <span className='color_orange'><i className="bi bi-currency-rupee"></i>
                    {users.Full_Sangam_Value1}</span></h3>
                </div>
                <div className='text-center text-dark'>
                  <h3>Win Amount <span className='color_green'><i className="bi bi-currency-rupee"></i>{users.Full_Sangam_Value2}</span></h3>

                </div>

              </div>
            </div>
          </>
        )}
        {/* <table class="table table-bordered">

          <tbody>
            {selectedMarket === '1' && (
              <>
               
               
             
       
           

              </>
            )}
              <>
                <tr>
                  <td colspan="2" className='text-center rounded bg-dark text-light'>Single Digit</td>
                </tr>
                <tr className='text-center'>
                  <td><span>Bid Amount</span><span><i className="bi bi-currency-rupee"></i> {users.single_digit_value1}</span></td>
                  <td><span>Win Amount</span><span><i className="bi bi-currency-rupee"></i> {users.single_digit_value2}</span></td>
                </tr>
                <tr>
                  <td colspan="2" className='text-center rounded bg-dark text-light'>Jodi Digit</td>
                </tr>
                <tr className='text-center'>
                  <td ><span>Bid Amount</span><span><i class="bi bi-currency-rupee"></i> {users.jodi_digit_value1}</span></td>
                  <td><span>Win Amount</span><span><i class="bi bi-currency-rupee"></i> {users.jodi_digit_value2}</span></td>
                </tr>
                <tr>
                  <td colspan="2" className='text-center rounded bg-dark text-light'>Single Pana</td>
                </tr>
                <tr className='text-center'>
                  <td ><span>Bid Amount</span><span><i class="bi bi-currency-rupee"></i> {users.single_pana_value1}</span></td>
                  <td><span>Win Amount</span><span><i class="bi bi-currency-rupee"></i> {users.single_pana_value2}</span></td>
                </tr>
                <tr>
                  <td colspan="2" className='text-center rounded bg-dark text-light'>Double Pana</td>
                </tr>
                <tr className='text-center'>
                  <td ><span>Bid Amount</span><span><i class="bi bi-currency-rupee"></i> {users.double_pana_value1}</span></td>
                  <td><span>Win Amount</span><span><i class="bi bi-currency-rupee"></i> {users.double_pana_value2}</span></td>
                </tr>
                <tr>
                  <td colspan="2" className='text-center rounded bg-dark text-light'>Triple Pana</td>
                </tr>
                <tr className='text-center'>
                  <td ><span>Bid Amount</span><span><i class="bi bi-currency-rupee"></i> {users.triple_pana_value1}</span></td>
                  <td><span>Win Amount</span><span><i class="bi bi-currency-rupee"></i> {users.triple_pana_value2}</span></td>
                </tr>
                <tr>
                  <td colspan="2" className='text-center rounded bg-dark text-light'>Half Sangam</td>
                </tr>
                <tr className='text-center'>
                  <td ><span>Bid Amount</span><span><i class="bi bi-currency-rupee"></i> {users.Half_Sangam_Value1}</span></td>
                  <td><span>Win Amount</span><span><i class="bi bi-currency-rupee"></i> {users.Half_Sangam_Value2}</span></td>
                </tr>
                <tr>
                  <td colspan="2" className='text-center rounded bg-dark text-light'>Full Sangam</td>
                </tr>
                <tr className='text-center'>
                  <td ><span>Bid Amount</span><span><i class="bi bi-currency-rupee"></i> {users.Full_Sangam_Value1}</span></td>
                  <td><span>Win Amount</span><span><i class="bi bi-currency-rupee"></i> {users.Full_Sangam_Value2}</span></td>
                </tr>
              </>
            )}




          </tbody>
        </table> */}
      </Container>
    </div>
  )
}

export default Marcetret