import React, { useState, useEffect } from "react";
import { Container, Button, Row, Col } from "react-bootstrap";
import Playgame from "./Playgame";
import { Link } from "react-router-dom";
import axios from "axios";
import { Carousel } from "antd";
import logo from "../../assets/img/logo.png";
import play from "../../assets/img/check-ch.png";
import call_bg from "../../assets/img/call_bg.png";
import whatsapp_bg from "../../assets/img/whatsapp_bg.png";

import playoff from "../../assets/img/icons8-cross-48.png";
import chart from "../../assets/img/icons8-signal-96.png";
// import call_bg from "../../assets/img/call_bg.png";
import { FaPlay } from "react-icons/fa";
import { VscDebugStart } from "react-icons/vsc";
import { FcStart } from "react-icons/fc";
import { RiBarChartGroupedLine } from "react-icons/ri";
import { FaArrowRight, FaTelegram } from "react-icons/fa";
import { IoIosCall } from "react-icons/io";
import { FaWhatsapp } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

import {
  StarFilled,
  FileTextOutlined,
  PlayCircleFilled,
} from "@ant-design/icons";

const phoneNumber = "+917891121110"; // Replace with your WhatsApp business number
const message = "Hello! I need assistance."; // Replace with your default message

const whatsappLink = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(
  message
)}`;
const phoneNumbertl = "+917891121110"; // Replace with your WhatsApp business number
const messagetl = "Hello! I need assistance."; // Replace with your default message

const TlLink = `tg://msg?phone=${phoneNumbertl}&text=${encodeURIComponent(messagetl)}`;
export default function Play() {
  const [users, setUsers] = useState([]);
  const [sliders, setUserslider] = useState([]);
  const [Slider_url, setSlider_url] = useState([]);
  const [usersdata, setUsersdata] = useState([]);
  const [loading1, setLoading] = useState(false);
  const [profiles, setProfiles] = useState([]);

  const navigate = useNavigate();
  const [iframeContent, setIframeContent] = useState(null);

  const handleReload = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    loaduser();
    loaduser1();
    slider();
  }, []);

  const loaduser = async () => {
    const user_id = localStorage.getItem("userid");
    const dev_id = localStorage.getItem("dev_id");
    // alert(user_id)
    setLoading(true);

    // alert(user_id);
    let url = `${process.env.REACT_APP_API_URL}/kalyan_market.php`;
    // console.warn(url);
    const formData = new FormData();
    formData.append("app_id", process.env.REACT_APP_API_ID);
    formData.append("user_id", user_id);
    formData.append("dev_id", dev_id);
    // alert(dev_id)
    var config = {
      method: "POST",
      url: url,
      body: formData,
    };
    axios
      .post(url, formData, config)
      .then(function (response) {
        const res = JSON.stringify(response.data.data);
        const objectRes = JSON.parse(res);
        setUsers(objectRes);
        console.warn(objectRes);
      })
      .finally(() => {
        // setTimeout(() => {
        setLoading(false);
        // }, 1000);
      });
  };
  const slider = async () => {
    let url = `${process.env.REACT_APP_API_URL}slider.php`;

    const formData = new FormData();
    // alert(dev_id)
    var config = {
      method: "POST",
      url: url,
      body: formData,
    };
    axios.post(url, formData, config).then(function (response) {
      const res = JSON.stringify(response.data.data);
      const baseurl = response.data.Slider_url;
      // console.warn(baseurl);
      setSlider_url(baseurl);
      console.warn(baseurl);
      const objectRes = JSON.parse(res);
      setUserslider(objectRes);
      console.warn(objectRes);
    });
  };



  const loaduser1 = async () => {
    const user_id = localStorage.getItem("userid");
    const dev_id = localStorage.getItem("dev_id");

    let url = `${process.env.REACT_APP_API_URL}/get_user_profile.php`;
    const formData = new FormData();
    formData.append("app_id", process.env.REACT_APP_API_ID);
    formData.append("user_id", user_id);
    formData.append("dev_id", dev_id);

    var config = {
      method: "POST",
      url: url,
      data: formData, // Use 'data' instead of 'body'
    };

    axios(config)
      .then(function (response) {
        console.log("API Response:", response);
        const res = response.data;
        if (res !== undefined) {
          // alert(res);
          setProfiles(res);
          console.warn(res);
        }
      })
      .catch(function (error) {
        console.error("Error fetching profile data: ", error);
      });
  };
  // const handleClick = (id) => {
  //   window.location.href = `/Pana?id=${id}`;
  // };
  const handleClick = (id, name) => {
    localStorage.setItem('market_id', id,);
    localStorage.setItem('market_name', name);
    // localStorage.setItem('market_name',name);
    // window.location.href = `/Jodi-Game`;
    navigate('/Pana');
  };





  const handleClick1 = async (id) => {
    navigate('/Result-history');
    localStorage.setItem('marketchart_id', id,);

  };


  const handleClickclose = (id, name) => {
    if ("vibrate" in navigator) {
      // vibration API supported
      navigator.vibrate(1000);
    }
  };


  return (
    <>
      {profiles && profiles.is_playstore == 1 ?
        <section id="play" className="margin-bottom-88 mb-0">
          <div className="playsection">

            <div className="height_65">

              <div className=" border-radius-8">
                <div className="">
                  {users &&
                    users.map((user) => (
                      <>
                        <div className="new">
                          {user.is_play == 1 ? (
                            <div className="d-flex align-items-center justify-content-between padding-5">
                              <div className="div text-dark text-center w-20">

                                <span onClick={() => {
                                  handleClick1(user.id);
                                }}>
                                  <img className="croseimg" src={chart} alt="" />
                                </span>

                                <span className="text-bold">Chart</span>
                              </div>
                              <div className="div  w-80">
                                <div className="marketname text-center">
                                  <h4 className="animate-charcter flex-wrap marketnamenew mb-0 d-flex justify-content-center market_name text-center text-dark">
                                    {user.name}
                                  </h4>
                                  {/* <small className="text-danger p-0">
                                  [***-**-***]
                                </small> */}
                                  <div className="inputbox">
                                    <p style={{ color: "black" }}>
                                      {user.marketResult1 == null ? '***' : user.marketResult1} - {user.marketResult2 == null ? '**' : user.marketResult2} - {user.marketResult3 == null ? '***' : user.marketResult3}
                                    </p>
                                  </div>

                                  <p
                                    onClick={() => {
                                      handleReload();
                                    }}
                                    className="playgames p-0 "
                                  >
                                    Market opened{" "}
                                  </p>

                                  {/* <div className="marketnamepaly">
                                    <div className="date_time">
                                      <p className="animate-charcter">
                                        <span className="openclose">Open:</span>{" "}
                                        {user.Opentime}
                                      </p>
                                      ||
                                      <p className="animate-charcter">
                                        Closed: {user.Closetime}
                                      </p>
                                    </div>
                                  </div> */}
                                </div>
                              </div>
                              {/* <div onClick={() => { handleClick(user.id); }} className="div text-dark w-20">
                  <span><img className="playimg" src={play} alt="" /></span>
                  <p className="openn">Open</p>
                </div> */}
                              <div
                                // onClick={() => {
                                //   handleClick(user.id, user.name);
                                // }}
                                className="div text-dark text-center w-20"
                              >
                                <span>
                                  <img className="croseimg" src={play} alt="" />
                                </span>
                                <span>Play</span>
                              </div>
                            </div>
                          ) : (
                            <div className="d-flex    align-items-center justify-content-between padding-5">
                              <div className="div text-dark text-center w-20">

                                <span onClick={() => {
                                  handleClick1(user.id);
                                }}>
                                  <img onClick={handleClickclose} className="croseimg" src={chart} alt="" />
                                </span>
                                <span >Chart</span>
                              </div>
                              <div className="div  w-80">
                                <div className="marketname text-center">
                                  <h4 className="animate-charcter flex-wrap marketnamenew mb-0 d-flex justify-content-center market_name text-center text-dark">
                                    {user.name}
                                  </h4>
                                  {/* <small className="text-danger p-0">
                                  [***-**-***]
                                </small> */}
                                  <div className="inputbox">
                                    <p style={{ color: "black" }}>
                                      {user.marketResult1 == null ? '***' : user.marketResult1} - {user.marketResult2 == null ? '**' : user.marketResult2} - {user.marketResult3 == null ? '***' : user.marketResult3}
                                    </p>
                                  </div>

                                  <p
                                    onClick={() => {
                                      handleReload();
                                    }}
                                    className="playgames1 p-0 text-danger "
                                  >
                                    Market Closed{" "}
                                  </p>
                                  {/* <div className="marketnamepaly ">
                                    <div className="date_time ">
                                      <p className="animate-charcter text-dark">
                                        Open: {user.Opentime}
                                      </p>
                                      ||
                                      <p className="animate-charcter">
                                        Close: {user.Closetime}
                                      </p>
                                    </div>
                                  </div> */}
                                </div>
                              </div>
                              <div className="div text-dark text-center w-20">
                                <span onClick={handleClickclose}>
                                  <img
                                    className="croseimg"
                                    src={playoff}
                                    alt=""
                                  />
                                </span>
                                <span>Close</span>
                              </div>
                            </div>
                          )}
                        </div>
                      </>
                    ))}
                </div>
              </div>
            </div>
          </div>
          {loading1 && (
            // <>
            // <div className="loader_roomcode">
            //   <img src={loader} alt="loader" />
            // </div>
            // </>
            <div className="spinner-wrapper">
              <div className="loadernew2"></div>
            </div>
          )}
        </section>
        : ""}
      {profiles && profiles.is_playstore == 0 ?
        <section id="play" className="margin-bottom-88 mb-0">
          <div className="playsection">
            <div className="playsection_fixed">
              <div className="fixed_carousel">
                <div className="heightcarosuksel">

                  <Carousel autoplay>
                    {sliders &&
                      sliders.map((user) => (
                        <div>
                          <img
                            // src={Slider_url/sliders}
                            src={`${Slider_url}/${user.images}`}
                            alt="banner"
                            className="img-fluid"
                          />
                        </div>
                      ))}

                  </Carousel>
                </div>
                <Container fluid className="p-0">

                  <Row
                    className="buttons gy-2 mx-0"
                    style={{
                      backgroundColor: "green",
                      BorderRadius: "5%",
                      margin: "0px",
                    }}
                  >
                    <Col xs={6} className="my-2  d-flex justify-content-center">
                      {/* <div className="support btn imageoffcall" > */}
                      {/* Your content */}
                      <a href="tel:+91-7891121110" className=" img_width_icond-flex jusitfy-content-start">
                        <div className="image_icon_social d-flex justify-content-center">

                          <img src={call_bg} alt="call" />
                        </div>

                      </a>
                      {/* </div> */}
                    </Col>

                    <Col xs={6} className="my-2  d-flex justify-content-center">
                      <a href={whatsappLink} target="_blank" className="d-flex jusitfy-content-start img_width_icon" rel="noopener noreferrer">

                        <div className="image_icon_social d-flex justify-content-center">
                          <img src={whatsapp_bg} alt="whatsapp" />

                        </div>

                      </a>
                    </Col>
                  </Row>

                </Container>

                <div
                  className="d-flex justify-content-between align-items-center bg-light"
                  style={{ marginBottom: "6px" }}
                >
                  <div className="buttons newbtn">
                    <div className="add_fund btn">
                      <Link to="/AddPayment " className="text-dark bg-light">
                        Add Fund
                      </Link>
                    </div>
                    <div className="add_fund btn">
                      <Link to="/Disawar-Market " className="text-dark bg-light">
                        Delhi
                      </Link>
                    </div>
                  </div>


                  <div className="buttons newbtn">
                    <div className="add_fund btn ">
                      <Link to="/WithdrawAmount" className="text-dark bg-light">
                        Withdraw
                      </Link>
                    </div>
                    <div className="add_fund btn">
                      <Link to="/Wallet" className="text-dark bg-light">
                        Wallet
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="height_65">

              <div className=" border-radius-8">
                <div className="playg">
                  {users &&
                    users.map((user) => (
                      <>
                        <div className="new">
                          {user.is_play == 1 ? (
                            <div className="d-flex align-items-center justify-content-between padding-5">
                              <div className="div text-dark text-center w-20">

                                <span onClick={() => {
                                  handleClick1(user.id);
                                }}>
                                  <img className="croseimg" src={chart} alt="" />
                                </span>

                                <span className="text-bold">Chart</span>
                              </div>
                              <div className="div  w-80">
                                <div className="marketname text-center">
                                  <h4 className="animate-charcter flex-wrap marketnamenew mb-0 d-flex justify-content-center market_name text-center text-dark">
                                    {user.name}
                                  </h4>
                                  {/* <small className="text-danger p-0">
                                  [***-**-***]
                                </small> */}
                                  <div className="inputbox">
                                    <p style={{ color: "black" }}>
                                      {user.marketResult1 == null ? '***' : user.marketResult1} - {user.marketResult2 == null ? '**' : user.marketResult2} - {user.marketResult3 == null ? '***' : user.marketResult3}
                                    </p>
                                  </div>

                                  <p
                                    onClick={() => {
                                      handleReload();
                                    }}
                                    className="playgames p-0 "
                                  >
                                    Market opened{" "}
                                  </p>

                                  <div className="marketnamepaly">
                                    <div className="date_time">
                                      <p className="animate-charcter">
                                        <span className="openclose">Open:</span>{" "}
                                        {user.Opentime}
                                      </p>
                                      ||
                                      <p className="animate-charcter">
                                        Closed: {user.Closetime}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* <div onClick={() => { handleClick(user.id); }} className="div text-dark w-20">
                  <span><img className="playimg" src={play} alt="" /></span>
                  <p className="openn">Open</p>
                </div> */}
                              <div
                                onClick={() => {
                                  handleClick(user.id, user.name);
                                }}
                                className="div text-dark text-center w-20"
                              >
                                <span>
                                  <img className="croseimg" src={play} alt="" />
                                </span>
                                <span>Play</span>
                              </div>
                            </div>
                          ) : (
                            <div className="d-flex    align-items-center justify-content-between padding-5">
                              <div className="div text-dark text-center w-20">

                                <span onClick={() => {
                                  handleClick1(user.id);
                                }}>
                                  <img onClick={handleClickclose} className="croseimg" src={chart} alt="" />
                                </span>
                                <span >Chart</span>
                              </div>
                              <div className="div  w-80">
                                <div className="marketname text-center">
                                  <h4 className="animate-charcter flex-wrap marketnamenew mb-0 d-flex justify-content-center market_name text-center text-dark">
                                    {user.name}
                                  </h4>
                                  {/* <small className="text-danger p-0">
                                  [***-**-***]
                                </small> */}
                                  <div className="inputbox">
                                    <p style={{ color: "black" }}>
                                      {user.marketResult1 == null ? '***' : user.marketResult1} - {user.marketResult2 == null ? '**' : user.marketResult2} - {user.marketResult3 == null ? '***' : user.marketResult3}
                                    </p>
                                  </div>

                                  <p
                                    onClick={() => {
                                      handleReload();
                                    }}
                                    className="playgames1 p-0 text-danger "
                                  >
                                    Market Closed{" "}
                                  </p>
                                  <div className="marketnamepaly ">
                                    <div className="date_time ">
                                      <p className="animate-charcter text-dark">
                                        Open: {user.Opentime}
                                      </p>
                                      ||
                                      <p className="animate-charcter">
                                        Close: {user.Closetime}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="div text-dark text-center w-20">
                                <span onClick={handleClickclose}>
                                  <img
                                    className="croseimg"
                                    src={playoff}
                                    alt=""
                                  />
                                </span>
                                <span>Close</span>
                              </div>
                            </div>
                          )}
                        </div>
                      </>
                    ))}
                </div>
              </div>
            </div>
          </div>
          {loading1 && (
            // <>
            // <div className="loader_roomcode">
            //   <img src={loader} alt="loader" />
            // </div>
            // </>
            <div className="spinner-wrapper">
              <div className="loadernew2"></div>
            </div>
          )}
        </section>
        : ""}
    </>
  );
}
